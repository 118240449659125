import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { layersIcon, analiticIllustration, eyeIcon, pencilIcon, eyeOffIcon, alertTriangleIcon, alertIcon, trashIcon, downloadIcon, partialBookGradient } from "@debionetwork/ui-icons";
import { queryElectronicMedicalRecordByOwnerId, queryElectronicMedicalRecordFileById, deregisterElectronicMedicalRecord, deregisterElectronicMedicalRecordFee } from "@debionetwork/polkadot-provider";
import CryptoJS from "crypto-js";
import Kilt from "@kiltprotocol/sdk-js";
import { u8aToHex } from "@polkadot/util";
import { errorHandler } from "@/common/lib/error-handler";
export default {
  name: "CustomerPHR",
  data: () => ({
    layersIcon,
    analiticIllustration,
    eyeIcon,
    pencilIcon,
    eyeOffIcon,
    trashIcon,
    downloadIcon,
    alertIcon,
    alertTriangleIcon,
    partialBookGradient,
    cardBlock: false,
    showModal: false,
    showModalPassword: false,
    showPassword: false,
    showTooltip: false,
    selectedFile: null,
    error: null,
    txWeight: null,
    publicKey: null,
    secretKey: null,
    headers: [{
      text: "Health Record Title",
      value: "title",
      sortable: true
    }, {
      text: "Category",
      value: "category",
      sortable: true
    }, {
      text: "Document Title",
      value: "documentTitle",
      sortable: true
    }, {
      text: "Description",
      value: "documentDescription",
      sortable: true
    }, {
      text: "Upload Date",
      value: "createdAt",
      align: "center",
      sortable: true
    }, {
      text: "Action",
      value: "actions",
      align: "center"
    }],
    phrDocuments: []
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      mnemonicData: state => state.substrate.mnemonicData,
      lastEventData: state => state.substrate.lastEventData,
      loadingData: state => state.auth.loadingData,
      web3: state => state.metamask.web3
    })
  },
  watch: {
    lastEventData() {
      if (this.lastEventData !== null) {
        const dataEvent = JSON.parse(this.lastEventData.data.toString());
        if (this.lastEventData.method === "ElectronicMedicalRecordRemoved") {
          if (dataEvent[1] === this.wallet.address) this.getPHRHistory();
        }
      }
    },
    mnemonicData(val) {
      if (val) this.initialDataKey();
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 959) this.cardBlock = true;else this.cardBlock = false;
    });
  },
  async created() {
    if (this.mnemonicData) this.initialDataKey();
    await this.getPHRHistory();
  },
  methods: {
    initialDataKey() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
    },
    async getPHRHistory() {
      this.showModal = false;
      this.isLoading = true;
      const documents = [];
      try {
        const dataPHR = await queryElectronicMedicalRecordByOwnerId(this.api, this.wallet.address);
        if (dataPHR !== null || !(dataPHR !== null && dataPHR !== void 0 && dataPHR.length)) {
          const listPHR = dataPHR.reduce((filtered, current) => {
            if (filtered.every(v => v.id !== current.id)) filtered.push(current);
            return filtered;
          }, []);
          listPHR.reverse();
          for (const phrDetail of listPHR) {
            const documentDetail = await this.preparePHRData(phrDetail);
            documents.push(documentDetail);
          }
        }
        this.phrDocuments = documents;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async preparePHRData(dataPHR) {
      let files = [];
      for (let i = 0; i < ((_dataPHR$files = dataPHR.files) === null || _dataPHR$files === void 0 ? void 0 : _dataPHR$files.length); i++) {
        var _dataPHR$files;
        const file = await queryElectronicMedicalRecordFileById(this.api, dataPHR.files[i]);
        dataPHR.createdAt = new Date(+file.uploadedAt.replaceAll(",", "")).toLocaleDateString("id", {
          day: "2-digit",
          month: "short",
          year: "numeric"
        });
        files.push({
          ...file,
          createdAt: new Date(+file.uploadedAt.replaceAll(",", "")).toLocaleDateString("id", {
            day: "2-digit",
            month: "short",
            year: "numeric"
          }),
          recordLink: file.recordLink
        });
      }
      return {
        id: dataPHR.id,
        title: dataPHR.title,
        category: dataPHR.category,
        createdAt: dataPHR.createdAt,
        files: files === null || files === void 0 ? void 0 : files.slice(0, 3)
      };
    },
    onEdit(phr) {
      this.$router.push({
        name: "customer-phr-edit",
        params: {
          id: phr.id
        }
      });
    },
    onDetails(phr) {
      this.$router.push({
        name: "customer-phr-details",
        params: {
          id: phr.id
        }
      });
    },
    handleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async handleOpenModalDelete(item) {
      this.selectedFile = item;
      this.showModal = true;
      const txWeight = await deregisterElectronicMedicalRecordFee(this.api, this.wallet, item.id);
      this.txWeight = "".concat(Number(this.web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(4), " DBIO");
    },
    handleShowTooltip(e) {
      if (e.type === "mouseenter") {
        this.showTooltip = true;
      } else {
        this.showTooltip = false;
      }
    },
    async onDelete() {
      const {
        id
      } = this.selectedFile;
      this.isLoading = true;
      try {
        await deregisterElectronicMedicalRecord(this.api, this.wallet, id);
        this.error = null;
        this.selectedFile = null;
      } catch (e) {
        const error = await errorHandler(e.message);
        this.isLoading = false;
        this.error = error;
      }
    }
  }
};